import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import CustomLink from "../Link/CustomLink"
import Image from "gatsby-image"
//components
import Section from "../../Section"
//data
const getData = graphql`
  {
    c: contentfulHomePage(node_locale: { eq: "fr" }) {
      title
      content {
        content
      }
      button1Title
      button1Ref {
        slug
      }
      button2Title
      button2Ref {
        slug
      }
      node_locale
      image {
        fluid {
          ...GatsbyContentfulFluid
        }
      }
    }
  }
`

const PresentHome = ({ pageContext }) => {
  const data = useStaticQuery(getData)
  return (
    <Section classname="homePresent" flex container>
      <div className="image">
        <Image fluid={data.c.image.fluid} />
      </div>
      <div className="present">
        <h2>{data.c.title}</h2>
        <p>{data.c.content.content}</p>
        <div>
          <CustomLink
            classname="button second"
            to={`/${data.c.node_locale}${data.c.button1Ref.slug}`}
          >
            {data.c.button1Title}
          </CustomLink>
        </div>
        <div>
          <CustomLink
            classname="button"
            to={`/${data.c.node_locale}${data.c.button2Ref.slug}`}
          >
            {data.c.button2Title}
          </CustomLink>
        </div>
      </div>
    </Section>
  )
}

export default PresentHome
